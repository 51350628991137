
import { is2x } from '@/shared/helpers/window'
import Gravatar from 'vue-gravatar';
import Records from '@/shared/services/records'

export default
  components:
    'v-gravatar': Gravatar
  props:
    user:
      type: Object
      default: -> Records.users.build()

    coordinator: Boolean
    size:
      type: [String, Number]
      default: 36
    noLink: Boolean
    colors: Object

  computed:
    width: ->
      if parseInt(@size)
        parseInt(@size)
      else
        switch @size
          when 'tiny'      then 20
          when 'small'     then 24
          when 'thirtysix' then 36
          when 'forty'     then 40
          when 'medium'    then 48
          when 'large'     then 64
          when 'featured'  then 200

    gravatarSize: ->
      if is2x() then 2*@width else @width

    uploadedAvatarUrl: ->
      return unless @user.avatarKind == 'uploaded'
      return @user.avatarUrl if typeof @user.avatarUrl is 'string'
      @user.avatarUrl['large']

    componentType:  ->
      if @noLink or !@user.username
        'div'
      else
        'router-link'

