
import ThreadService from '@/shared/services/thread_service'
import AbilityService from '@/shared/services/ability_service'
import { pick, some } from 'lodash'

export default
  props:
    thread: Object

    groupPage:
      type: Boolean
      default: false

    showGroupName:
      type: Boolean
      default: true

  computed:
    dockActions: ->
      pick(ThreadService.actions(@thread, @), ['dismiss_thread'])

    menuActions: ->
      actions = if @groupPage
        if @$vuetify.breakpoint.smAndDown
          ['dismiss_thread','pin_thread', 'unpin_thread', "edit_tags", 'move_thread', 'close_thread', 'reopen_thread', 'discard_thread']
        else
          ['pin_thread', 'unpin_thread', "edit_tags", 'move_thread', 'close_thread', 'reopen_thread', 'discard_thread']
      else
        if @$vuetify.breakpoint.smAndDown
          ['dismiss_thread', "edit_tags", 'close_thread', 'reopen_thread']
        else
          ["edit_tags", 'close_thread', 'reopen_thread']
      pick(ThreadService.actions(@thread, @), actions)

    canPerformAny: ->
      some @menuActions, (action) -> action.canPerform()

