
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import AppConfig      from '@/shared/services/app_config'

export default
  props:
    discussion:
      type: Object
      required: true
    close: Function
  data: ->
    discussionTags: @discussion.tagNames || []
    loading: false
  methods:
    updateTags: ->
      setTimeout =>
        @loading = true
        Records.tags.updateModel(@discussion, @discussionTags).then =>
          EventBus.$emit 'closeModal'
        .finally =>
          @loading = false
  computed:
    groupTags: -> @discussion.group().parentOrSelf().tagNames || []

