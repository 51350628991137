
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import utils          from '@/shared/record_store/utils'
import LmoUrlService  from '@/shared/services/lmo_url_service'
import AbilityService from '@/shared/services/ability_service'
import Session from '@/shared/services/session'
import AppConfig      from '@/shared/services/app_config'
import Flash   from '@/shared/services/flash'
import { audiencesFor, audienceValuesFor, audienceSize } from '@/shared/helpers/announcement'
import {each , sortBy, includes, map, pull, uniq, throttle, debounce, merge, orderBy, some, filter} from 'lodash'
import { encodeParams } from '@/shared/helpers/encode_params'
import { onError } from '@/shared/helpers/form'
import md5 from 'md5'

export default
  props:
    close: Function
    announcement:
      type: Object
      required: true

  data: ->
    query: ''
    recipients: []
    searchResults: []
    upgradeUrl: AppConfig.baseUrl + 'upgrade'
    invitationsRemaining: 1000
    showInvitationsRemaining: false
    subscriptionActive: true
    canInvite: true
    maxMembers: 0
    invitedGroupIds: if @announcement.model.isA('group') then [@announcement.model.id] else []
    historyOpen: false

  created: ->
    @searchResults = if @invitingToGroup then [] else @announcement.model.members()
    @maxMembers = @announcement.model.group().parentOrSelf().subscription.max_members || 0
    if @invitingToGroup
      @announcement.model.fetchToken()

    if @announcement.model.group()
      @invitationsRemaining =
        (@announcement.model.group().parentOrSelf().subscription.max_members || 0) -
        @announcement.model.group().parentOrSelf().orgMembershipsCount

      @showInvitationsRemaining =
        @invitingToGroup &&
        @announcement.model.group().parentOrSelf().subscription.max_members

      @subscriptionActive = @announcement.model.group().parentOrSelf().subscription.active

      @canInvite = @subscriptionActive && (!@announcement.model.group().parentOrSelf().subscription.max_members || @invitationsRemaining > 0)

  methods:
    submit: ->
      @announcement.recipients = @recipients
      @announcement.invitedGroupIds = @invitedGroupIds
      @announcement.save()
      .then (data) =>
        @announcement.membershipsCount = data.memberships.length if @announcement.model.isA('group')
        @announcement.membershipsCount = data.discussion_readers.length if @announcement.model.isA('discussion')
        @announcement.membershipsCount = data.stances.length if @announcement.model.isA('poll')
        @announcement.membershipsCount = data.users.length if @announcement.model.isA('outcome')

        Flash.success('announcement.flash.success', { count: @announcement.membershipsCount })
        @close()
      .catch onError(@announcement)

    tooManyInvitations: ->
        @showInvitationsRemaining && (@invitationsRemaining < @recipients.length)

    audiences: -> filter audiencesFor(@announcement.model), @audienceSize

    audienceValues: -> audienceValuesFor(@announcement.model)
    audienceSize: (audience) -> audienceSize(@announcement.model, audience)

    search: debounce (query) ->
      Records.announcements.search(query, @announcement.model).then (data) =>
        if data && data.length == 1 && data[0].id == 'multiple'
          each map(data[0].emails, @buildRecipientFromEmail), @addRecipient
        else
          @searchResults = uniq @recipients.concat utils.parseJSONList(data)
    , 300
    , {trailing: true}

    buildRecipientFromEmail: (email) ->
      id: email
      name: email
      email: email
      emailHash: md5(email)
      avatarKind: 'mdi-email-outline'

    copied: (e) ->
      @$copyText(e.text, @$refs.copyContainer.$el)
      Flash.success('common.copied')

    remove: (item) ->
      index = @recipients.indexOf(item)
      if (index >= 0)
        @recipients.splice(index, 1)

    addRecipient: (recipient) ->
      return unless recipient
      return if some(@recipients, (r) -> r.emailHash == recipient.emailHash)
      @searchResults.unshift recipient
      @recipients.unshift recipient
      @query = ''

    loadAudience: (kind) ->
      Records.announcements.fetchAudience(@announcement.model, kind).then (data) =>
        each sortBy(utils.parseJSONList(data), (e) => e.name || e.email ), @addRecipient

    closeHistory: -> @historyOpen = false

  computed:
    guestCount: -> @recipients.filter((r) -> r.email).length
    modelKind: -> @announcement.model.constructor.singular
    pollType: -> @announcement.model.pollType
    translatedPollType: -> @announcement.model.poll().translatedPollType() if @announcement.model.isA('poll') or @announcement.model.isA('outcome')

    invitableGroups: ->
      return [] unless @announcement.model.isA('group')
      if @announcement.model.isParent()
        @announcement.model.subgroups().filter (g) -> AbilityService.canAddMembersToGroup(g)
      else
        orderBy(@announcement.model.parent().subgroups().concat([@announcement.model.parent()]).filter((g) -> AbilityService.canAddMembersToGroup(g)), ['parentId', 'name'], ['desc', 'asc'])

    canUpdateAnyoneCanParticipate: ->
      @announcement.model.isA('poll') &&
      AbilityService.canAdminister(@announcement.model)

    invitingToGroup: ->
      @announcement.model.isA('group')

    previewUrl: ->
      AppConfig.baseUrl + 'api/v1/announcements/preview?' + encodeParams(merge(@announcement.model.namedId(), {kind: @announcement.kind}))

  watch:
    query: (q) ->
      @search q if q && q.length > 2
