
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import utils          from '@/shared/record_store/utils'
import LmoUrlService  from '@/shared/services/lmo_url_service'
import AbilityService from '@/shared/services/ability_service'

import Flash   from '@/shared/services/flash'


export default
  props:
    group: Object
  data: ->
    dialog: false

  mounted: ->
    @group.fetchToken()

  methods:
    error: ->
      Flash.error('invitation_form.error')

    copiedGroupUrl: (e) ->
      @$copyText(e.text, @$refs.groupUrlButton.$el)
      Flash.success('common.copied')

    copiedInvitationUrl: (e) ->
      @$copyText(e.text, @$refs.invitationUrlButton.$el)
      Flash.success('common.copied')

    resetInvitationLink: ->
      @group.resetToken().then =>
        Flash.success('invitation_form.shareable_link_reset')

  computed:
    groupUrl: ->
      LmoUrlService.group(@group, null, { absolute: true })

    invitationLink: ->
      if @group.token
        LmoUrlService.shareableLink(@group)
      else
        @$t('common.action.loading')

    canAddMembers: ->
      AbilityService.canAddMembersToGroup(@group) && !@pending

