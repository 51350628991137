
import AppConfig       from '@/shared/services/app_config'
import Session         from '@/shared/services/session'
import Records         from '@/shared/services/records'
import UserHelpService from '@/shared/services/user_help_service'
import Flash from '@/shared/services/flash'

export default
  methods:
    togglePinned: ->
      if @user.experiences['sidebar']
        Records.users.removeExperience('sidebar')
      else
        Records.users.saveExperience('sidebar')

    toggleBeta: ->
      if @user.experiences['betaFeatures']
        Records.users.removeExperience('betaFeatures')
      else
        Records.users.saveExperience('betaFeatures')
        Flash.success("user_dropdown.beta_collab")

    signOut: ->
      Session.signOut()

  computed:
    siteName: -> AppConfig.theme.site_name
    user:     -> Session.user()
    helpLink: -> UserHelpService.helpLink()
    showHelp: -> AppConfig.features.app.help_link

