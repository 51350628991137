import { render, staticRenderFns } from "./form.vue?vue&type=template&id=6e5b9da8&lang=pug&"
import script from "./form.vue?vue&type=script&lang=coffee&"
export * from "./form.vue?vue&type=script&lang=coffee&"
import style0 from "./form.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import AnnouncementHistory from '@/components/announcement/history.vue'
import DismissModalButton from '@/components/common/dismiss_modal_button.vue'
import Space from '@/components/common/space.vue'
import SubmitOverlay from '@/components/common/submit_overlay.vue'
import UserAvatar from '@/components/user/avatar.vue'
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {AnnouncementHistory,DismissModalButton,Space,SubmitOverlay,UserAvatar,VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VChip,VDialog,VLayout,VListItemAvatar,VListItemContent,VListItemTitle,VSpacer})
