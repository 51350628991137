import { render, staticRenderFns } from "./modal.vue?vue&type=template&id=20d9c026&lang=pug&"
import script from "./modal.vue?vue&type=script&lang=coffee&"
export * from "./modal.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import AuthComplete from '@/components/auth/complete.vue'
import AuthForm from '@/components/auth/form.vue'
import AuthIdentityForm from '@/components/auth/identity_form.vue'
import AuthSigninForm from '@/components/auth/signin_form.vue'
import AuthSignupForm from '@/components/auth/signup_form.vue'
installComponents(component, {AuthComplete,AuthForm,AuthIdentityForm,AuthSigninForm,AuthSignupForm})
