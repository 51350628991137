
import EventBus from '@/shared/services/event_bus'
import AppConfig from '@/shared/services/app_config'
import Records from '@/shared/services/records'
import Flash  from '@/shared/services/flash'
import openModal from '@/shared/helpers/open_modal'

export default
  props:
    close: Function
    group: Object

  data: ->
    webhooks: []
    loading: true

  mounted: ->
    Records.webhooks.fetch(params: {group_id: @group.id}).then => @loading = false
    @watchRecords
      collections: ["webhooks"]
      query: (records) =>
        @webhooks = records.webhooks.find(groupId: @group.id)

  methods:
    add: ->
      openModal
        component: 'WebhookForm'
        props:
          group: @group
    edit: (webhook)->
      openModal
        component: 'WebhookForm'
        props:
          group: @group
          model: webhook

    confirmRemove: (webhook) ->
      openModal
        component: 'ConfirmModal'
        props:
          confirm:
            submit: webhook.destroy
            text:
              title: 'webhook.remove'
              raw_helptext: @$t('webhook.confirm_remove', {name: webhook.name})
              flash: 'webhook.removed'

