
import { eventHeadline, eventTitle, eventPollType } from '@/shared/helpers/helptext'
import Records        from '@/shared/services/records'

export default
  props:
    event: Object

  computed:
    headline: ->
      actor = (@event.actor() || @eventable.author() || Records.users.anonymous())
      @$t eventHeadline(@event, true ), # useNesting
        author:   actor.nameWithTitle(@eventable.group())
        username: actor.username
        key:      @event.model().key
        title:    eventTitle(@event)
        polltype: @$t(eventPollType(@event)).toLowerCase()
    eventable: -> @event.model()
