import { render, staticRenderFns } from "./activity_panel.vue?vue&type=template&id=50d073c1&lang=pug&"
import script from "./activity_panel.vue?vue&type=script&lang=coffee&"
export * from "./activity_panel.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import Space from '@/components/common/space.vue'
import ThreadRenderer from '@/components/thread/renderer.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {Space,ThreadRenderer,VBtn})
