
import Session        from '@/shared/services/session'
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import LmoUrlService  from '@/shared/services/lmo_url_service'

import { eventHeadline, eventTitle, eventPollType } from '@/shared/helpers/helptext'
import { includes, camelCase } from 'lodash'
import RangeSet from '@/shared/services/range_set'

export default
  props:
    isReturning: Boolean
    event:
      type: Object
      required: true

  data: ->
    isDisabled: false
    collapsed: false
    hover: false
    focusStyleClass: null

  methods:
    viewed: (viewed) -> @event.markAsRead() if viewed
    focusThenFade: ->
      @focusStyleClass = 'thread-item--focused'
      setTimeout =>
        @focusStyleClass = 'thread-item--previously-focused'
      , 5000

  computed:
    ariaTranslationKey: ->
      if @eventable.discardedAt
        'thread_item.aria.deleted'
      else
        'thread_item.aria.'+@event.kind
    eventable: -> @event.model()

    translatedPollType: ->
      @eventable.translatedPollType() if @eventable && @eventable.pollType

    indentSize: ->
      switch @event.depth
        when 0 then 0
        when 1 then 0
        when 2 then 12 + 40
        when 3 then 68

    discussion: -> @event.discussion()

    iconSize: -> if (@event.depth == 1) then 40 else 24

    isUnread: ->
      Session.isSignedIn() &&
      Session.user().id != @event.actorId &&
      @isReturning && @discussion &&
      !RangeSet.includesValue(@discussion.readRanges, @event.sequenceId)

    headline: ->
      actor = (@event.actor() || @eventable.author() || Records.users.anonymous())
      @$t eventHeadline(@event, true ), # useNesting
        author:   actor.nameWithTitle(@eventable.group())
        username: actor.username
        key:      @event.model().key
        title:    eventTitle(@event)
        polltype: @$t(eventPollType(@event)).toLowerCase()

    link: ->
      LmoUrlService.event @event

  watch:
    '$route.query.p':
      immediate: true
      handler: (newVal) ->
        @focusThenFade() if parseInt(newVal) == @event.position && @event.depth == 1

    '$route.params.sequence_id':
      immediate: true
      handler: (newVal) ->
        @focusThenFade() if parseInt(newVal) == @event.sequenceId

    '$route.params.comment_id':
      immediate: true
      handler: (newVal) ->
        @focusThenFade() if parseInt(newVal) == @event.eventableId

