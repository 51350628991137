
import AppConfig      from '@/shared/services/app_config'
import AbilityService from '@/shared/services/ability_service'
import Records  from '@/shared/services/records'
import Flash   from '@/shared/services/flash'
import { isEmpty, compact } from 'lodash'
import { onError } from '@/shared/helpers/form'
import i18n from '@/i18n.coffee'

export default
  props:
    group: Object
    close: Function
  data: ->
    submitting: false
    survey: null
    categories: [
      'member'
      'nonprofit'
      'professional'
      'board'
      'government'
      'other'
    ]
    sizes: ['ten', 'twenty', 'fifty', 'two_hundred', 'five_hundred', 'two_thousand', 'else']
    uses: ['governance', 'collaboration', 'engagement', 'self_management', 'remote', 'document', 'decision_making', 'funding', 'project', 'forum', 'other']
    referrers: ['google', 'invitation', 'referral', 'social', 'capterra', 'other']
    desiredFeatures: ['polls', 'discussions', 'archive']
    usage: []
    rules: {
      required: (input) ->
        if input && input.length
          true
        else
          i18n.t('common.required')
    }
  created: ->
    @survey = Records.groupSurveys.build
                groupId: @group.id

  methods:
    submit: ->
      @submitting = true
      if @$refs.form.validate()
        @survey.save()
          .then (data) =>
            Flash.success('group_survey.success')
            @close()
            @submitting = false
          .catch(onError(@survey, () => @submitting = false))
      else
        Flash.error('group_survey.validation_error')
        @submitting = false
