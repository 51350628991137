
import Records        from '@/shared/services/records'
import { orderBy } from 'lodash'

export default
  props:
    close: Function
    discussion: Object
  data: ->
    historyData: []
    historyLoading: false
    historyError: false

  created: ->
    @historyLoading = true
    Records.discussions.fetchHistoryFor(@discussion).then (data) =>
      @historyLoading = false
      @historyData = orderBy(data, ['last_read_at'], ['desc']) || []
    , (err) =>
      @historyLoading = false
      @historyError = true
