import { render, staticRenderFns } from "./preview.vue?vue&type=template&id=51978e6c&lang=pug&"
import script from "./preview.vue?vue&type=script&lang=coffee&"
export * from "./preview.vue?vue&type=script&lang=coffee&"
import style0 from "./preview.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import PollCommonChartPreview from '@/components/poll/common/chart_preview.vue'
import PollCommonClosingAt from '@/components/poll/common/closing_at.vue'
import Space from '@/components/common/space.vue'
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {PollCommonChartPreview,PollCommonClosingAt,Space,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})
