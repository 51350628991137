
import Records            from '@/shared/services/records'
import AbilityService     from '@/shared/services/ability_service'
import EventBus           from '@/shared/services/event_bus'
import RecordLoader       from '@/shared/services/record_loader'
import ThreadFilter       from '@/shared/services/thread_filter'
import { map, debounce, orderBy, intersection, compact, omit, filter, concat, uniq } from 'lodash'
import Session from '@/shared/services/session'

export default
  created: ->
    @onQueryInput = debounce (val) =>
      @$router.replace(@mergeQuery(q: val))
    , 500
    @init()

  data: ->
    group: null
    discussions: []
    searchResults: []
    loader: null
    searchLoader: null
    groupIds: []

  methods:
    routeQuery: (o) ->
      @$router.replace(@mergeQuery(o))

    openStartDiscussionModal: ->
      EventBus.$emit 'openModal',
        component: 'DiscussionForm'
        props:
          discussion: Records.discussions.build
            descriptionFormat: Session.defaultFormat()
            groupId: @group.id

    beforeDestroy: ->
      EventBus.$off 'joinedGroup'

    init: ->
      Records.groups.findOrFetch(@$route.params.key).then (group) =>
        @group = group

        EventBus.$emit 'currentComponent',
          page: 'groupPage'
          title: @group.name
          group: @group
          search:
            placeholder: @$t('navbar.search_threads', name: @group.parentOrSelf().name)

        EventBus.$on 'joinedGroup', (group) => @fetch()

        @loader = new RecordLoader
          collection: 'discussions'
          params:
            group_id: @group.id
            exclude_types: 'group outcome'

        @searchLoader = new RecordLoader
          collection: 'searchResults'
          params:
            exclude_types: 'group stance outcome poll'
            subgroups: @$route.query.subgroups || 'all'
            group_id: @group.id

        @watchRecords
          key: @group.id
          collections: ['discussions', 'groups', 'memberships']
          query: (store) => @query(store)

        @refresh()

    refresh: ->
      @from = 0
      @fetch()
      @query()

    query: (store) ->
      return unless @group
      @publicGroupIds = @group.publicOrganisationIds()

      @groupIds = switch (@$route.query.subgroups || 'mine')
        when 'mine' then uniq(concat(intersection(@group.organisationIds(), Session.user().groupIds()), @publicGroupIds, @group.id)) # @group.id is present if @group is a subgroup of parentgroup that i'm a member of, and that parent group has parentMembersCanSeeDiscussions
        when 'all' then @group.organisationIds()
        else [@group.id]

      if @$route.query.q
        chain = Records.searchResults.collection.chain()
        chain = chain.find(resultGroupId: {$in: @group.parentOrSelf().organisationIds()})
        chain = chain.find(query: @$route.query.q).data()
        @searchResults = orderBy(chain, 'rank', 'desc')
      else
        chain = Records.discussions.collection.chain()
        chain = chain.find(discardedAt: null)
        chain = chain.find(groupId: {$in: @groupIds})

        switch @$route.query.t
          when 'unread'
            chain = chain.where (discussion) -> discussion.isUnread()
          when 'closed'
            chain = chain.find(closedAt: {$ne: null})
          when 'all'
            true # noop
          else
            chain = chain.find(closedAt: null)

        if @$route.query.tag
          chain = chain.find({tagNames: {'$contains': @$route.query.tag}})

        @discussions = chain.data()

    fetch: ->
      if @$route.query.q
        @searchLoader.fetchRecords(q: @$route.query.q)
      else
        params = {from: @from}
        params.filter = 'show_closed' if @$route.query.t == 'closed'
        params.filter = 'all' if @$route.query.t == 'all'
        params.subgroups = @$route.query.subgroups || 'mine'
        params.tags = @$route.query.tag
        @loader.fetchRecords(params)

    filterName: (filter) ->
      switch filter
        when 'unread' then 'discussions_panel.unread'
        when 'all' then 'discussions_panel.all'
        when 'closed' then 'discussions_panel.closed'
        when 'subscribed' then 'change_volume_form.simple.loud'
        else
          'discussions_panel.open'


  watch:
    '$route.params': 'init'
    '$route.query': 'refresh'

  computed:
    showViewClosedThreads: -> @noMoreToLoad && !@$route.query.t
    viewClosedThreadsUrl: -> @urlFor(@group) + "?t=closed"
    noMoreToLoad: -> @loader.numLoaded > 0 && @loader.exhausted

    pinnedDiscussions: ->
      orderBy(@discussions.filter((discussion) -> discussion.pinned), ['title'], ['asc'])

    regularDiscussions: ->
      orderBy(@discussions.filter((discussion) -> !discussion.pinned), ['lastActivityAt'], ['desc'])

    groupTags: ->
      @group && @group.parentOrSelf().tagNames || []

    loading: ->
      @loader.loading || @searchLoader.loading

    noThreads: ->
      !@loading && @discussions.length == 0

    canViewPrivateContent: ->
      AbilityService.canViewPrivateContent(@group)

    canStartThread: ->
      AbilityService.canStartThread(@group)

    isLoggedIn: ->
      Session.isSignedIn()

    unreadCount: ->
      filter(@discussions, (discussion) -> discussion.isUnread()).length

