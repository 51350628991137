
import Session        from '@/shared/services/session'
import AbilityService from '@/shared/services/ability_service'
import { map, sortBy, filter } from 'lodash'
import AppConfig from '@/shared/services/app_config'
import Records from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import Flash   from '@/shared/services/flash'
import { onError } from '@/shared/helpers/form'

export default
  props:
    discussion: Object
    close: Function
    isPage: Boolean

  data: ->
    upgradeUrl: AppConfig.baseUrl + 'upgrade'
    availableGroups: []
    submitIsDisabled: false

  mounted: ->
    @watchRecords
      collections: ['groups', 'memberships']
      query: (store) =>
        @availableGroups = filter(Session.user().groups(), (group) -> AbilityService.canStartThread(group))

  methods:
    submit: ->
      actionName = if @discussion.isNew() then 'created' else 'updated'
      @discussion.save()
      .then (data) =>
        discussionKey = data.discussions[0].key
        Records.discussions.findOrFetchById(discussionKey, {}, true).then (discussion) =>
          Flash.success("discussion_form.messages.#{actionName}")
          @$router.push @urlFor(discussion)
          if @discussion.isNew()
            if AbilityService.canAnnounceTo(discussion)
              EventBus.$emit 'openModal',
                component: 'AnnouncementForm',
                props:
                  announcement: Records.announcements.buildFromModel(discussion)
      .catch onError(@discussion)


    updatePrivacy: ->
      @discussion.private = @discussion.privateDefaultValue()

  computed:
    privacyTitle: ->
      if @discussion.private
        'common.privacy.private'
      else
        'common.privacy.public'

    privacyDescription: ->

      path = if @discussion.private == false
               'discussion_form.privacy_public'
             else if @discussion.group().parentMembersCanSeeDiscussions
               'discussion_form.privacy_organisation'
             else
               'discussion_form.privacy_private'
      @$t(path, {group:  @discussion.group().name, parent: @discussion.group().parentName()})

    groupSelectOptions: ->
      sortBy map(@availableGroups, (group) -> {
         text: group.fullName,
         value: group.id
      }), 'fullName'

    maxThreads: ->
      return null unless @discussion.group()
      @discussion.group().parentOrSelf().subscription.max_threads

    threadCount: ->
      return unless @discussion.group()
      @discussion.group().parentOrSelf().orgDiscussionsCount

    maxThreadsReached: ->
      @maxThreads && @threadCount >= @maxThreads

    subscriptionActive: ->
      return true unless @discussion.group()
      @discussion.group().parentOrSelf().subscription.active

    canStartThread: ->
      @subscriptionActive && !@maxThreadsReached

    showUpgradeMessage: ->
      @discussion.isNew() && !@canStartThread

    isMovingItems: ->
      @discussion.isForking

