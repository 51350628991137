
import EventBus from '@/shared/services/event_bus'
import AppConfig from '@/shared/services/app_config'
import Session from '@/shared/services/session'

import {sum, map, sortBy, find, compact, uniq} from 'lodash'

export default
  props:
    poll: Object
    zone: Object
  data: ->
    pollOptions: []
    latestStances: []
    participants: []
    stancesByUserId: []

  created: ->
    @watchRecords
      collections: ['stances', 'poll_options']
      query: (store) =>
        @latestStances = @poll.latestStances()

        @stancesByUserId = {}
        @latestStances.forEach (stance) =>
          @stancesByUserId[stance.participantId] = stance

        @pollOptions = @poll.pollOptions()
        @participants = @poll.participants()

  methods:
    scoreColor: (score) ->
      switch score
        when 2 then AppConfig.pollColors.proposal[0]
        when 1 then AppConfig.pollColors.proposal[1]
        when 0 then AppConfig.pollColors.proposal[2]

    bgColor: (score) ->
      switch score
        when 2 then "rgba(0, 209, 119, 0.5)"
        when 1 then "rgba(246, 168, 43, 0.5)"

    yesVotersFor: (option) ->
      uniq @choicesFor(option, 2).map (choice) -> choice.stance().participant()

    maybeVotersFor: (option) ->
      uniq @choicesFor(option, 1).map (choice) -> choice.stance().participant()

    choicesFor: (option, score) ->
      compact @latestStances.map (stance) ->
        find stance.stanceChoices(), (choice) ->
          choice.pollOption() == option && choice.score == score

    totalFor: (option) ->
      sum map(option.stanceChoices().filter((choice) -> choice.stance().latest), 'score')

    barLength: (count) ->
      ((count * 32)) + 'px'

    scoreFor: (user, option) ->
      if @stancesByUserId[user.id]
        @stancesByUserId[user.id].scoreFor(option)
      else
        0

    classForScore: (score) ->
      switch score
        when 2 then 'poll-meeting-chart__cell--yes'
        when 1 then 'poll-meeting-chart__cell--maybe'
        when 0 then 'poll-meeting-chart__cell--no'

  computed:
    currentUserTimeZone: ->
      Session.user().timeZone
