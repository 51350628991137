
import Records           from '@/shared/services/records'
import RecordLoader      from '@/shared/services/record_loader'
import EventBus          from '@/shared/services/event_bus'
import AbilityService    from '@/shared/services/ability_service'
import Session           from '@/shared/services/session'
import AttachmentService from '@/shared/services/attachment_service'

import { isEmpty, intersection, debounce, filter, some, orderBy } from 'lodash'

export default
  data: ->
    group: null
    loader: null
    attachmentLoader: null
    searchQuery: ''
    items: []
    subgroups: 'mine'
    per: 25
    from: 0

  created: ->
    @onQueryInput = debounce (val) =>
      @$router.replace({ query: { q: val } })
    , 500

    @group = Records.groups.fuzzyFind(@$route.params.key)

    EventBus.$emit 'currentComponent',
      page: 'groupPage'
      title: @group.name
      group: @group
      search:
        placeholder: @$t('navbar.search_files', name: @group.parentOrSelf().name)

    @loader = new RecordLoader
      collection: 'documents'
      path: 'for_group'
      params:
        group_id: @group.id
        per: @per
        subgroups: @subgroups
        from: @from

    @attachmentLoader = new RecordLoader
      collection: 'attachments'
      params:
        group_id: @group.id
        per: @per
        subgroups: @subgroups
        from: @from

    @watchRecords
      collections: ['documents', 'attachments']
      query: => @query()

    @searchQuery = @$route.query.q || ''
    @fetch()

  watch:
    '$route.query.q': (val) ->
      @searchQuery = val || ''
      @fetch()
      @query()

  methods:
    query: ->
      groupIds = switch @subgroups
        when 'none' then [@group.id]
        when 'mine' then intersection(@group.organisationIds(), Session.user().groupIds())
        when 'all' then @group.organisationIds()

      documents = Records.documents.collection.chain().
                     find(groupId: {$in: groupIds}).
                     find(title: {$regex: ///#{@searchQuery}///i}).
                     limit(@from + @per).data()

      attachments = Records.attachments.collection.chain().
                     find(groupId: {$in: groupIds}).
                     find(filename: {$regex: ///#{@searchQuery}///i}).
                     limit(@from + @per).data()

      @items = orderBy(documents.concat(attachments), 'createdAt', 'desc')

    fetch: ->
      @loader.fetchRecords
        q: @searchQuery

      @attachmentLoader.fetchRecords
        q: @searchQuery

    actionsFor: (item) ->
      AttachmentService.actions(item)

  computed:
    showLoadMore: -> !@loader.exhausted && !@attachmentLoader.exhausted
    loading: -> @loader.loading || @attachmentLoader.loading
    canAdminister: -> AbilityService.canAdminister(@group)

