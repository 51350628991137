
import Records       from '@/shared/services/records'
import openModal      from '@/shared/helpers/open_modal'
import Session       from '@/shared/services/session'
import {every, invokeMap} from 'lodash'
import { differenceInDays } from 'date-fns'
export default
  props:
    group: Object
  data: ->
    activities: []
  created: ->
    @activities = [
      translate: "set_description"
      complete:  => @group.description
      click:     =>
        openModal
          component: 'GroupForm'
          props:
            group: @group
    ,
      translate: "set_logo"
      complete:  => @group.logoUrl() != '/theme/icon.png'
      click:     =>
        openModal
          component: 'GroupForm'
          props:
            group: @group
    ,
      translate: "set_cover_photo"
      complete:  => @group.hasCustomCover
      click:     =>
        openModal
          component: 'GroupForm'
          props:
            group: @group
    ,
      translate: "invite_people_in"
      complete:  => @group.membershipsCount > 1 or @group.invitationsCount > 0
      click:     =>
        openModal
          component: 'AnnouncementForm'
          props:
            announcement: Records.announcements.buildFromModel(@group)
    ,
      translate: "start_thread"
      complete:  => @group.discussionsCount > 1
      click:     =>
        openModal
          component: 'DiscussionForm'
          props:
            discussion: Records.discussions.build(groupId: @group.id)
    ]
  methods:
    translationFor: (key) ->
      "loomio_onboarding.group_progress_card.activities.#{key}"

    close: ->
      Records.users.saveExperience("dismissProgressCard")

  computed:
    setupComplete: ->
      every invokeMap(@activities, 'complete')

    show: ->
      (differenceInDays(new Date, Session.user().createdAt) < 30) && # account is less than 30 days old
      @group.isParent() && 
      @group.adminsInclude(Session.user()) &&
      !Session.user().hasExperienced("dismissProgressCard")


