
import Records from '@/shared/services/records'
import { fieldFromTemplate } from '@/shared/helpers/poll'
import Flash from '@/shared/services/flash'
import EventBus from '@/shared/services/event_bus'

import Vue     from 'vue'
import { exact } from '@/shared/helpers/format_time'
import { parseISO } from 'date-fns'
import { map, sortBy, head, find } from 'lodash'
import { onError } from '@/shared/helpers/form'

export default
  props:
    outcome: Object
    close: Function

  data: ->
    options: []
    bestOption: null
    clone: null
    isDisabled: false

  created: ->
    @clone = @outcome.clone()

    if @datesAsOptions()
      @options = map @clone.poll().pollOptions(), (option) ->
        id:        option.id
        value:     exact(parseISO(option.name))
        attendees: option.stances().length

      @bestOption = head sortBy @options, (option) ->
        -1 * option.attendees # sort descending, so the best option is first

      Vue.set(@clone, 'calendarInvite', true)

      @clone.pollOptionId = @outcome.pollOptionId or @bestOption.id
      @clone.customFields.event_summary = @clone.customFields.event_summary or @clone.poll().title

  methods:
    submit: ->
      @clone.customFields.should_send_calendar_invite = @clone.calendarInvite
      @clone.customFields.event_description = @clone.statement if @datesAsOptions()

      if @clone.isNew()
        actionName = "created"
      else
        actionName = "updated"

      @clone.save()
      .then (data) =>
        eventData = find(data.events, (event) => event.kind == 'outcome_created') || {}
        event = Records.events.find(eventData.id)
        Flash.success("poll_common_outcome_form.outcome_#{actionName}")
        @closeModal()
        EventBus.$emit 'openModal',
          component: 'AnnouncementForm'
          props:
            announcement: Records.announcements.buildFromModel(event)

      .catch onError(@clone)

    datesAsOptions: ->
      fieldFromTemplate @clone.poll().pollType, 'dates_as_options'

