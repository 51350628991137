
import Records from '@/shared/services/records'
import Session from '@/shared/services/session'
import AppConfig from '@/shared/services/app_config'
import FileUploader from '@/shared/services/file_uploader'
import FilesList from './files_list.vue'
import EventBus from '@/shared/services/event_bus'
import I18n from '@/i18n'
import { convertToMd } from '@/shared/services/format_converter'

import { Editor, EditorContent, EditorMenuBar } from 'tiptap'

import { Blockquote, CodeBlock, HardBreak, HorizontalRule,
  OrderedList, BulletList, ListItem, Table, TableHeader, TableCell,
  TableRow, TodoList, Bold, Code, Italic, Link, Strike, Underline,
  History, Mention, Placeholder, TrailingNode } from 'tiptap-extensions'
import Collaboration from '@/shared/tiptap_extentions/collaboration.js'

import ForeColor from '@/shared/tiptap_extentions/fore_color'
import BackColor from '@/shared/tiptap_extentions/back_color'
import FormatClear from '@/shared/tiptap_extentions/format_clear'
import Align from '@/shared/tiptap_extentions/align'
import Alignment from '@/shared/tiptap_extentions/alignment'
import Paragraph from '@/shared/tiptap_extentions/paragraph'
import Heading from '@/shared/tiptap_extentions/heading'

import Iframe from './iframe'
import TodoItem from './todo_item'

import { insertText } from 'tiptap-commands'
import Image from '@/shared/tiptap_extentions/image.js'

import {getEmbedLink} from '@/shared/helpers/embed_link.coffee'

import { CommonMentioning, HtmlMentioning, MentionPluginConfig } from './mentioning.coffee'
import SuggestionList from './suggestion_list'
import Attaching from './attaching.coffee'
import {compact} from 'lodash'
import TextHighlightBtn from './text_highlight_btn'
import TextAlignBtn from './text_align_btn'

import io from 'socket.io-client'

export default
  mixins: [CommonMentioning, HtmlMentioning, Attaching]
  props:
    model: Object
    field: String
    label: String
    placeholder: String
    maxLength: Number
    shouldReset: Boolean
    autofocus: Boolean

  components:
    EditorContent: EditorContent
    EditorMenuBar: EditorMenuBar
    FilesList: FilesList
    SuggestionList: SuggestionList
    TextHighlightBtn: TextHighlightBtn
    TextAlignBtn: TextAlignBtn

  data: ->
    loading: true
    socket: null
    count: 0
    editor: null
    expanded: null
    closeEmojiMenu: false
    linkUrl: ""
    iframeUrl: ""
    linkDialogIsOpen: false
    iframeDialogIsOpen: false

  computed:
    format: ->
      @model["#{@field}Format"]

  mounted: ->
    @expanded = Session.user().experiences['html-editor.expanded']

    @socket = io(@tiptapAddress())
      .on('init', (data) => @onInit(data))
      .on('update', (data) =>
        @editor.extensions.options.collaboration.update(data)
        @editor.extensions.options.collaboration.updateCursors(data)
      )
      .on('getCount', (count) => @setCount(count))
      .on('cursorupdate', (data) =>
        this.editor.extensions.options.collaboration.updateCursors(data)
      )

  watch:
    'shouldReset': 'reset'

  methods:
    onInit: ({doc, version}) ->
      @loading = false
      @editor.destroy() if @editor

      @editor = new Editor
        editorProps:
          scrollThreshold: 100
          scrollMargin: 100
        extensions: [
          new Link(),
          new Mention(MentionPluginConfig.bind(@)()),
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Image({attachFile: @attachFile, attachImageFile: @attachImageFile}),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Table(),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Iframe(),
          new ForeColor(),
          new BackColor(),
          new FormatClear(),
          new Align(),
          new Alignment(),
          new Paragraph(),
          new Placeholder({
            emptyClass: 'is-empty',
            emptyNodeText: @placeholder,
            showOnlyWhenEditable: true,
          }),
          new Collaboration({
            socket: @socket,
            user: Session.user()
            version: version
            debounce: 250
          })
        ]
        content: doc
        onUpdate: @updateModel
        autoFocus: @autofocus

      @editor.setContent(@model[@field]) if version == 0

      # setTimeout =>
      #   if @$refs.editor && @$refs.editor.$el
      #     @$refs.editor.$el.children[0].setAttribute("role", "textbox")
      #     @$refs.editor.$el.children[0].setAttribute("aria-label", @placeholder) if @placeholder

    setCount: (count) ->
      @count = count

    tiptapAddress: ->
      if @model.isNew()
        compact([AppConfig.theme.channels_uri, 'tiptap', @model.constructor.singular, 'new', @model.groupId, @model.discussionId, @model.parentId, Session.user().secretToken]).join('/')
      else
        [AppConfig.theme.channels_uri, 'tiptap', @model.constructor.singular, @model.id, (@model.secretToken || Session.user().secretToken)].join('/')

    selectedText: ->
      { selection, state } = @editor
      { from, to } = selection
      state.doc.textBetween(from, to, ' ')

    reset: ->
      @editor.clearContent()
      @resetFiles()

    convertToMd: ->
      if confirm I18n.t('formatting.markdown_confirm')
        convertToMd(@model, @field)
        Records.users.saveExperience('html-editor.uses-markdown')

    toggleExpanded: ->
      if !@expanded
        @expanded = true
        Records.users.saveExperience('html-editor.expanded')
      else
        @expanded = false
        Records.users.removeExperience('html-editor.expanded')

    setLinkUrl: (command) ->
      if @linkUrl
        @linkUrl = "http://".concat(@linkUrl) unless @linkUrl.includes("://")
        command({ href: @linkUrl })
        @linkUrl = null
      @linkDialogIsOpen = fal/isNewse
      @editor.focus()

    setIframeUrl: (command) ->
      command({ src: getEmbedLink(@iframeUrl) })
      @iframeUrl = null
      @iframeDialogIsOpen = false
      @editor.focus()

    emojiPicked: (shortcode, unicode) ->
      { view } = this.editor
      insertText(unicode)(view.state, view.dispatch, view)
      @closeEmojiMenu = false
      @editor.focus()

    updateModel: ->
      @model[@field] = @editor.getHTML()
      @updateFiles()

  beforeDestroy: ->
    @editor.destroy() if @editor
    @socket.close() if @socket

