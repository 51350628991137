
import EventBus    from '@/shared/services/event_bus'
import AuthService from '@/shared/services/auth_service'
import AppConfig from '@/shared/services/app_config'
import Session from '@/shared/services/session'
import AuthModalMixin from '@/mixins/auth_modal'
import Flash from '@/shared/services/flash'
import VueRecaptcha from 'vue-recaptcha';
import openModal      from '@/shared/helpers/open_modal'

export default
  components:
    'v-recaptcha': VueRecaptcha
  mixins: [AuthModalMixin]

  props:
    user: Object

  mounted: ->
    EventBus.$emit 'set-auth-modal-title', "auth_form.create_account"

  data: ->
    siteName: AppConfig.theme.site_name
    vars: {name: @user.name, site_name: AppConfig.theme.site_name}
    loading: false

  methods:
    submit: ->
      if @useRecaptcha
        @$refs.invisibleRecaptcha.execute()
      else
        @submitForm()

    submitForm: (recaptcha) ->
      @user.recaptcha = recaptcha
      if AuthService.validSignup(@vars, @user)
        @loading = true
        AuthService.signUp(@user).finally =>
          @loading = false
  computed:
    recaptchaKey: -> AppConfig.recaptchaKey
    termsUrl: -> AppConfig.theme.terms_url
    privacyUrl: -> AppConfig.theme.privacy_url
    newsletterEnabled: -> AppConfig.newsletterEnabled
    allow: ->
      AppConfig.features.app.create_user or AppConfig.pendingIdentity.identity_type?
    useRecaptcha: ->
      @recaptchaKey && !@user.hasToken && !(AppConfig.pendingIdentity || {}).has_token

