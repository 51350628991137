
import EventBus from '@/shared/services/event_bus'
import AppConfig from '@/shared/services/app_config'
import Records from '@/shared/services/records'
import Flash  from '@/shared/services/flash'

export default
  props:
    close: Function
    group: Object
    model: Object

  data: ->
    webhook: @model || Records.webhooks.build(groupId: @group.id)
    kinds: AppConfig.webhookEventKinds
    formats: [
      {text: @$t('webhook.formats.markdown'), value: "markdown"}
      {text: @$t('webhook.formats.microsoft'), value: "microsoft"}
      {text: @$t('webhook.formats.slack'), value: "slack"}
    ]

  methods:
    submit: ->
      @webhook.save()
      .then =>
        Flash.success 'webhook.success'
        @close()
