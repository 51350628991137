import { render, staticRenderFns } from "./edit_comment_form.vue?vue&type=template&id=45429514&lang=pug&"
import script from "./edit_comment_form.vue?vue&type=script&lang=coffee&"
export * from "./edit_comment_form.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import CommentForm from '@/components/thread/comment_form.vue'
import DismissModalButton from '@/components/common/dismiss_modal_button.vue'
import SubmitOverlay from '@/components/common/submit_overlay.vue'
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {CommentForm,DismissModalButton,SubmitOverlay,VCard,VCardTitle,VSpacer})
