import { render, staticRenderFns } from "./vote_form.vue?vue&type=template&id=3ef57ff2&lang=pug&"
import script from "./vote_form.vue?vue&type=script&lang=coffee&"
export * from "./vote_form.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import PollCommonAddOptionButton from '@/components/poll/common/add_option_button.vue'
import PollCommonStanceReason from '@/components/poll/common/stance_reason.vue'
import SortableItem from '@/components/common/sortable_item.vue'
import SortableList from '@/components/common/sortable_list.vue'
import Space from '@/components/common/space.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import ValidationErrors from '@/components/common/validation_errors.vue'
installComponents(component, {PollCommonAddOptionButton,PollCommonStanceReason,SortableItem,SortableList,Space,VBtn,VCardActions,VChip,VSpacer,ValidationErrors})
