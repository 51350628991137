
import OutcomeService from '@/shared/services/outcome_service'
import parseISO from 'date-fns/parseISO'
export default
  props:
    poll: Object
  data: ->
    outcome: @poll.outcome()
  methods:
    parseISO: parseISO
  computed:
    actions: -> OutcomeService.actions(@outcome, @)
  created: ->
    @watchRecords
      collections: ['outcome']
      query: (records) =>
        @outcome = @poll.outcome()

