
import Records        from '@/shared/services/records'
import AbilityService from '@/shared/services/ability_service'
import Session        from '@/shared/services/session'
import EventBus from '@/shared/services/event_bus'

export default
  props:
    poll: Object
  methods:
    showPanel: ->
      !@poll.outcome() and AbilityService.canSetPollOutcome(@poll)

    openOutcomeForm: ->
      outcome = @poll.outcome() or
      Records.outcomes.build
        pollId: @poll.id
        statementFormat: Session.defaultFormat()
      EventBus.$emit 'openModal',
        component: 'PollCommonOutcomeModal'
        props:
          outcome: outcome

