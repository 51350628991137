import { render, staticRenderFns } from "./outcome_panel.vue?vue&type=template&id=e0f02888&lang=pug&"
import script from "./outcome_panel.vue?vue&type=script&lang=coffee&"
export * from "./outcome_panel.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import DocumentList from '@/components/document/list.vue'
import FormattedText from '@/components/common/formatted_text.vue'
import Space from '@/components/common/space.vue'
import TimeAgo from '@/components/common/time_ago.vue'
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {ActionDock,DocumentList,FormattedText,Space,TimeAgo,VLayout,VSheet,VSpacer})
