
import EventBus from '@/shared/services/event_bus'
import Records from '@/shared/services/records'
import { debounce, truncate, first, last, some, drop, min, compact } from 'lodash'

export default
  props:
    discussion: Object
    loader: Object

  data: ->
    presets: []
    headings: []

  mounted: ->
    # EventBus.$on 'currentComponent', (options) =>
      # @discussion = options.discussion
      # return unless @discussion

    parser = new DOMParser()
    doc = parser.parseFromString(@discussion.description, 'text/html')
    @headings = Array.from(doc.querySelectorAll('h1,h2,h3')).map (el) =>
      {id: el.id, name: el.textContent}

    @watchRecords
      key: 'strand-nav'+@discussion.id
      collections: ["events", "discussions"]
      query: =>
        return unless @discussion && @discussion.createdEvent()
        @presets = Records.events.collection.chain()
          .find({pinned: true, discussionId: @discussion.id})
          .simplesort('position').data()

    # move this to activity panel.
    # Records.events.fetch
    #   params:
    #     exclude_types: 'group discussion'
    #     discussion_id: @discussion.id
    #     pinned: true
    #     per: 200

