import { render, staticRenderFns } from "./new_comment.vue?vue&type=template&id=18b55e61&lang=pug&"
import script from "./new_comment.vue?vue&type=script&lang=coffee&"
export * from "./new_comment.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import AttachmentList from '@/components/thread/attachment_list.vue'
import CommentForm from '@/components/thread/comment_form.vue'
import DocumentList from '@/components/document/list.vue'
import FormattedText from '@/components/common/formatted_text.vue'
import StrandItemHeadline from '@/components/strand/item/headline.vue'
installComponents(component, {ActionDock,AttachmentList,CommentForm,DocumentList,FormattedText,StrandItemHeadline})
