import { render, staticRenderFns } from "./details_panel.vue?vue&type=template&id=3a5dbdb7&lang=pug&"
import script from "./details_panel.vue?vue&type=script&lang=coffee&"
export * from "./details_panel.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import AttachmentList from '@/components/thread/attachment_list.vue'
import DocumentList from '@/components/document/list.vue'
import FormattedText from '@/components/common/formatted_text.vue'
import MidDot from '@/components/common/mid_dot.vue'
import PollCommonClosingAt from '@/components/poll/common/closing_at.vue'
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {AttachmentList,DocumentList,FormattedText,MidDot,PollCommonClosingAt,VSubheader})
