
import Records from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import Flash  from '@/shared/services/flash'
import { onError } from '@/shared/helpers/form'

export default
  props:
    poll: Object
    close: Function

  computed:
    title_key: ->
      mode = if @poll.isNew()
        'start'
      else
        'edit'
      'poll_' + @poll.pollType + '_form.'+mode+'_header'

    isEditing: ->
      @poll.closingAt && !@poll.isNew()

  methods:
    submit: ->
      actionName = if @poll.isNew() then 'created' else 'updated'
      @poll.customFields.deanonymize_after_close = @poll.deanonymizeAfterClose if @poll.anonymous
      @poll.customFields.can_respond_maybe = @poll.canRespondMaybe if @poll.pollType == 'meeting'
      @poll.setErrors({})
      @poll.save()
      .then (data) =>
        pollKey = data.polls[0].key
        Records.polls.findOrFetchById(pollKey, {}, true).then (poll) =>
          if !@poll.discussionId
            @$router.replace(@urlFor(poll)).catch (err) => {}
          Flash.success "poll_#{poll.pollType}_form.#{poll.pollType}_#{actionName}"
          @close()
          return if actionName == 'updated'
          EventBus.$emit 'openModal',
            component: 'AnnouncementForm',
            props: { announcement: Records.announcements.buildFromModel(poll) }

      .catch onError(@poll)

