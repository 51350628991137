
export default
  props:
    query: String
    mentionable: Array
    positionStyles: Object
    navigatedUserIndex: Number
    showUsername:
      type: Boolean
      default: false
