import { render, staticRenderFns } from "./poll_created.vue?vue&type=template&id=47bf7a51&lang=pug&"
import script from "./poll_created.vue?vue&type=script&lang=coffee&"
export * from "./poll_created.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import AttachmentList from '@/components/thread/attachment_list.vue'
import DocumentList from '@/components/document/list.vue'
import FormattedText from '@/components/common/formatted_text.vue'
import PollCommonActionPanel from '@/components/poll/common/action_panel.vue'
import PollCommonClosingAt from '@/components/poll/common/closing_at.vue'
import PollCommonDirective from '@/components/poll/common/directive.vue'
import PollCommonOutcomePanel from '@/components/poll/common/outcome_panel.vue'
import PollCommonPercentVoted from '@/components/poll/common/percent_voted.vue'
import PollCommonSetOutcomePanel from '@/components/poll/common/set_outcome_panel.vue'
import PollCommonVotesPanel from '@/components/poll/common/votes_panel.vue'
import ThreadItem from '@/components/thread/item.vue'
import Translation from '@/components/common/translation.vue'
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {ActionDock,AttachmentList,DocumentList,FormattedText,PollCommonActionPanel,PollCommonClosingAt,PollCommonDirective,PollCommonOutcomePanel,PollCommonPercentVoted,PollCommonSetOutcomePanel,PollCommonVotesPanel,ThreadItem,Translation,VLayout})
